import "./guest-form.scss";
import React, {forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import {useForm} from 'react-hook-form'
import {FormContainer, TextFieldElement, CheckboxElement, PasswordElement, SelectElement} from 'react-hook-form-mui'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography, useTheme
} from "@mui/material";
import ReCaptcha from "@matt-block/react-recaptcha-v2";
import httpService from "../../services/utils/http.service";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AutoComplete from "react-google-autocomplete";

export interface GuestComponentMethods {
    submitForm: () => void
}

export const GuestForm = forwardRef<GuestComponentMethods, any>((props: {
    disabled?: boolean,
    nationChange?: (nationCode: string) => void,
    confirm: (data: any) => void,
    recaptcha: boolean | undefined,
    setRecaptcha: (value: boolean) => void
}, ref) => {

    const theme = useTheme();
    const [place, setPlace] = React.useState<any>({});
    const autocomplete = useRef<any>(null);

    const formContext = useForm<{
        nome: string,
        cognome: string,
        email: string,
        password: string,
        ragione_sociale: string,
        partita_iva: string,
        codice_fiscale: string,
        codice_sdi: string,
        telefono: string,
        indirizzo: string,
        nazione: string,
        localita: string,
        provincia: string,
        cap: string,
        same_ship: boolean,
        nazione_ship: string,
        localita_ship: string,
        provincia_ship: string,
        indirizzo_ship: string,
        indirizzo2_ship: string,
        cap_ship: string,
        telefono_ship: string,
        riferimento: string,
        nazione_codice_ship: string,
        formatted_address_ship: string,
        google_address_ship: any,
        // comment: string,
        'g-recaptcha-response': string
    }>({
        defaultValues: {
            same_ship: true,
            nazione: 'IT',
            nazione_ship: 'IT'
        },
        shouldFocusError: true
    })
    const {watch} = formContext;
    const submitRef = React.useRef<any>(null);
    const submitForm = () => {
        submitRef?.current?.click();
    }
    useImperativeHandle(ref, () => ({
        submitForm
    }));

    const validation = {
        required: 'Campo obbligatorio',
    };

    const [checkedPrivacy, setCheckedPrivacy] = React.useState(true);
    const [countriesList, setCountriesList] = React.useState<any>([]);

    const [customerType, setCustomerType] = React.useState<number>(1);


    useEffect(() => {
        httpService.rest({
            type: "get",
            path: `user/countries`
        }).then((res) => {
            setCountriesList(res);
        });
    }, []);

    const handleChangePrivacy = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedPrivacy(event.target.checked);
    };

    const handleCustomerType = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (parseInt(event.target.value) === 1)   {
            if (props.nationChange) {
                props.nationChange(watch('nazione'));
            }
            formContext.setValue('same_ship', true);
        } else {
            if (props.nationChange) {
                props.nationChange(watch('nazione_ship'));
            }
            formContext.setValue('same_ship', false);
        }
        setCustomerType(parseInt(event.target.value));
    }

    const parseGoogleAddress = (address: any) => {
        const num = address.address_components.find((e: any) => e.types.indexOf('street_number') !== -1);
        const street = address.address_components.find((e: any) => e.types.indexOf('route') !== -1);
        const city = address.address_components.find((e: any) => e.types.indexOf('locality') !== -1 || e.types.indexOf('administrative_area_level_3') !== -1);
        const province = address.address_components.find((e: any) => e.types.indexOf('administrative_area_level_2') !== -1);
        const cap = address.address_components.find((e: any) => e.types.indexOf('postal_code') !== -1);
        const country = address.address_components.find((e: any) => e.types.indexOf('country') !== -1);
        const formatted_address = address.formatted_address;
        return {
            num: num ? num.long_name : '',
            street: street ? street.long_name : '',
            city: city ? city.long_name : '',
            province: province ? province.long_name : '',
            cap: cap ? cap.long_name : '',
            country: country ? country.long_name : '',
            country_code: country ? country.short_name : '',
            formatted_address: formatted_address
        }
    }

    const setAddress = (address: any) => {
        setPlace(address);
        const addressComponents = parseGoogleAddress(address);
        formContext.setValue('nazione', addressComponents.country);
        formContext.setValue('nazione_codice_ship', addressComponents.country_code);
        formContext.setValue('indirizzo', `${addressComponents.street} ${addressComponents.num}`);
        formContext.setValue('localita', addressComponents.city);
        formContext.setValue('provincia', addressComponents.province);
        formContext.setValue('cap', addressComponents.cap);
        formContext.setValue('formatted_address_ship', addressComponents.formatted_address);
        formContext.setValue('google_address_ship', address);
        if (props.nationChange && customerType === 1) {
            props.nationChange(addressComponents.country_code);
        }
    }

    const setAddressBusiness = (address: any) => {
        setPlace(address);
        const addressComponents = parseGoogleAddress(address);
        formContext.setValue('nazione_ship', addressComponents.country);
        formContext.setValue('nazione_codice_ship', addressComponents.country_code);
        formContext.setValue('indirizzo_ship', `${addressComponents.street} ${addressComponents.num}`);
        formContext.setValue('localita_ship', addressComponents.city);
        formContext.setValue('provincia_ship', addressComponents.province);
        formContext.setValue('cap_ship', addressComponents.cap);
        formContext.setValue('formatted_address_ship', addressComponents.formatted_address);
        formContext.setValue('google_address_ship', address);
        if (props.nationChange && customerType === 1) {
            props.nationChange(addressComponents.country_code);
        }
    }

    return (
        <React.Fragment>
            <Box
                sx={{
                    '& .MuiTypography-root': {
                        my: 1,
                        fontSize: '20px',
                        textTransform: 'uppercase'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 0,
                        borderWidth: 0
                    },
                    '& .MuiTextField-root': {
                        width: '100%',
                        backgroundColor: 'secondary.main',
                        mx: 0
                    },
                    mt: 4,
                }}
            >
                {/* Guest / Login */}
                <Grid container spacing={2} px={1} mb={4}>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            sx={{
                                height: '40px',
                                lineHeight: 1,
                                backgroundColor: 'warning.main',
                                color: 'primary.main',
                                textTransform: 'uppercase',
                                fontSize: '25px',
                                [theme.breakpoints.down('sm')]: {
                                    fontSize: '16px',
                                }
                            }}
                        >
                            Ordina come ospite
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={() => window.location.href = "/login?returnUrl=/checkout"}
                            sx={{
                                height: '40px',
                                lineHeight: 1,
                                backgroundColor: 'secondary.main',
                                color: 'primary.main',
                                textTransform: 'uppercase',
                                fontSize: '25px',
                                [theme.breakpoints.down('sm')]: {
                                    fontSize: '16px',
                                }
                            }}
                        >
                            Accedi / Registrati
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup
                            row
                            value={customerType}
                            onChange={handleCustomerType}
                            sx={{
                                justifyContent: 'space-around'
                            }}
                        >
                            <FormControlLabel key={0} value={1}
                                              control={<Radio color={'warning'}
                                                              sx={{color: 'secondary.main'}}/>}
                                              label={'Sei un privato'}/>
                            <FormControlLabel key={0} value={2}
                                              control={<Radio color={'warning'}
                                                              sx={{color: 'secondary.main'}}/>}
                                              label={'Sei un\'azienda'}/>
                        </RadioGroup>
                    </Grid>
                </Grid>


                <FormContainer
                    formContext={formContext}
                    onSuccess={data => props.confirm(data)}
                    shouldFocusError={true}
                >
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon color={'secondary'}/>}
                            aria-controls="payments"
                            id="payments"
                            sx={{
                                backgroundColor: 'primary.main',
                                color: 'secondary.main',
                            }}
                        >
                            <Typography variant="h4" sx={{
                                position: 'relative',
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                fontWeight: '600',
                                my: 4,
                                width: '100%',
                                [theme.breakpoints.up('sm')]: {
                                    '::before, ::after': {
                                        position: 'absolute',
                                        top: '50%',
                                        overflow: 'hidden',
                                        width: 'calc(41% - 140px)',
                                        height: '1px',
                                        backgroundColor: '#fff',
                                        content: '""'
                                    },
                                    '::before': {
                                        left: 0
                                    },
                                    '::after': {
                                        right: 0
                                    }
                                }
                            }}>{customerType === 1 ? 'Dati personali' : 'Dati aziendali'}</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{
                            backgroundColor: 'primary.main',
                            color: 'secondary.main',
                        }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        Nome
                                    </Typography>
                                    <TextFieldElement
                                        required
                                        name="nome"
                                        validation={validation}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        Cognome
                                    </Typography>
                                    <TextFieldElement
                                        required
                                        name="cognome"
                                        validation={validation}
                                    />
                                </Grid>
                                <Grid item xs={15}>
                                    <Typography variant="body1">
                                        Email
                                    </Typography>
                                    <TextFieldElement
                                        required
                                        name="email"
                                        validation={validation}
                                    />
                                </Grid>
                                {customerType === 1 && (
                                    <>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            Codice fiscale
                                        </Typography>
                                        <TextFieldElement
                                            required
                                            name="codice_fiscale"
                                            validation={{
                                                ...validation,
                                                minLength: {
                                                    value: 16,
                                                    message: 'Il codice fiscale deve essere di 16 caratteri'
                                                },
                                                maxLength: {
                                                    value: 16,
                                                    message: 'Il codice fiscale deve essere di 16 caratteri'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    </>
                                )}
                                {customerType === 2 && (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="body1">
                                                Ragione sociale
                                            </Typography>
                                            <TextFieldElement
                                                required
                                                name="ragione_sociale"
                                                validation={validation}
                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1">
                                                Indirizzo mail PEC
                                            </Typography>
                                            <TextFieldElement
                                                name="pec"
                                                required
                                                validation={validation}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                                Codice SDI
                                            </Typography>
                                            <TextFieldElement
                                                name="codice_sdi"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">
                                                Partita IVA
                                            </Typography>
                                            <TextFieldElement
                                                name="partita_iva"
                                                required
                                                validation={{
                                                    ...validation,
                                                    minLength: {
                                                        value: 11,
                                                        message: 'La partita iva deve essere di 11 caratteri'
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">
                                                Codice fiscale
                                            </Typography>
                                            <TextFieldElement
                                                name="codice_fiscale"
                                                required
                                                validation={{
                                                    ...validation,
                                                    minLength: {
                                                        value: 11,
                                                        message: 'Il codice fiscale deve essere di 11 o 16 caratteri'
                                                    },
                                                    maxLength: {
                                                        value: 16,
                                                        message: 'Il codice fiscale deve essere di 11 o 16 caratteri'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">
                                                Nazione
                                            </Typography>
                                            <SelectElement
                                                required
                                                name="nazione"
                                                options={countriesList}
                                                onChange={(e) => {
                                                    if (props.nationChange && watch('same_ship')) {
                                                        props.nationChange(e);
                                                    }
                                                }}
                                                valueKey="id"
                                                className="half"
                                                validation={validation}
                                            />
                                        </Grid>
                                        <Grid item xs={6}/>
                                        <Grid item xs={12}>
                                            <Typography variant="body1">
                                                Indirizzo di fatturazione
                                            </Typography>
                                            <TextFieldElement
                                                required
                                                name="indirizzo"
                                                className="half"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                                CAP
                                            </Typography>
                                            <TextFieldElement
                                                required
                                                name="cap"
                                                validation={validation}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                                Città
                                            </Typography>
                                            <TextFieldElement
                                                required
                                                name="localita"
                                                validation={validation}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                                Provincia
                                            </Typography>
                                            <TextFieldElement
                                                required
                                                name="provincia"
                                                label="Provincia"
                                                className="half"
                                                validation={validation}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">
                                                Nome referente
                                            </Typography>
                                            <TextFieldElement
                                                required
                                                name="riferimento"
                                                validation={validation}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">
                                                Cellulare
                                            </Typography>
                                            <TextFieldElement
                                                required
                                                name="telefono"
                                                validation={validation}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        Password
                                    </Typography>
                                    <PasswordElement
                                        required
                                        name="password"
                                        helperText="(Almeno una lettera maiuscola, una minuscola, un numero e un carattere speciale)"
                                        validation={validation}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    {/* START: Consegna privati */}
                    {customerType === 1 && (
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon color={'secondary'}/>}
                                aria-controls="payments"
                                id="payments"
                                sx={{
                                    backgroundColor: 'primary.main',
                                    color: 'secondary.main',
                                }}
                            >
                                <Typography variant="h4" sx={{
                                    position: 'relative',
                                    textAlign: 'center',
                                    textTransform: 'uppercase',
                                    fontWeight: '600',
                                    my: 4,
                                    width: '100%',
                                    [theme.breakpoints.up('sm')]: {
                                        '::before, ::after': {
                                            position: 'absolute',
                                            top: '50%',
                                            overflow: 'hidden',
                                            width: 'calc(41% - 140px)',
                                            height: '1px',
                                            backgroundColor: '#fff',
                                            content: '""'
                                        },
                                        '::before': {
                                            left: 0
                                        },
                                        '::after': {
                                            right: 0
                                        }
                                    }
                                }}>Indirizzo di consegna</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{
                                backgroundColor: 'primary.main',
                                color: 'secondary.main',
                            }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            Indirizzo*
                                        </Typography>
                                        <AutoComplete
                                            ref={autocomplete}
                                            className={'autoInputWhite'}
                                            apiKey={'AIzaSyCUaGSi9hPaFrFAiy1LobhmtmJ0KCSvr1k'}
                                            onPlaceSelected={(place) => {
                                                setAddress(place);
                                            }}
                                            onBlur={() => {
                                                if (place.formatted_address) {
                                                    setAddress(place);
                                                }
                                            }}
                                            options={{
                                                types: ['address']
                                            }}
                                            placeholder={'Inserisci un indirizzo'}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="body1">
                                            Nome referente*
                                        </Typography>
                                        <TextFieldElement
                                            required
                                            name="riferimento"
                                            validation={validation}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">
                                            Cellulare*
                                        </Typography>
                                        <TextFieldElement
                                            required
                                            name="telefono"
                                            validation={validation}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            Informazioni aggiutive
                                        </Typography>
                                        <TextFieldElement
                                            name="indirizzo2_ship"
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )}

                    {/* START: Consegna business */}
                    {customerType === 2 && (
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon color={'secondary'}/>}
                                aria-controls="payments"
                                id="payments"
                                sx={{
                                    backgroundColor: 'primary.main',
                                    color: 'secondary.main',
                                }}
                            >
                                <Typography variant="h4" sx={{
                                    position: 'relative',
                                    textAlign: 'center',
                                    textTransform: 'uppercase',
                                    fontWeight: '600',
                                    my: 4,
                                    width: '100%',
                                    [theme.breakpoints.up('sm')]: {
                                        '::before, ::after': {
                                            position: 'absolute',
                                            top: '50%',
                                            overflow: 'hidden',
                                            width: 'calc(41% - 140px)',
                                            height: '1px',
                                            backgroundColor: '#fff',
                                            content: '""'
                                        },
                                        '::before': {
                                            left: 0
                                        },
                                        '::after': {
                                            right: 0
                                        }
                                    }
                                }}>Indirizzo di consegna</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{
                                backgroundColor: 'primary.main',
                                color: 'secondary.main',
                            }}>
                                {/*<div className="full" style={{marginLeft: '1%'}}>*/}
                                {/*    <CheckboxElement*/}
                                {/*        name="same_ship"*/}
                                {/*        color={'primary'}*/}
                                {/*        sx={{*/}
                                {/*            backgroundColor: 'secondary.main',*/}
                                {/*            '&:hover': {*/}
                                {/*                backgroundColor: 'secondary.main'*/}
                                {/*            }*/}
                                {/*        }}*/}
                                {/*        label="Utilizza lo stesso indirizzo di fatturazione per la tua spedizione."*/}
                                {/*        onClick={(e: any) => {*/}
                                {/*            if (props.nationChange && e.target.checked) {*/}
                                {/*                props.nationChange(watch('nazione'));*/}
                                {/*            } else if (props.nationChange && !e.target.checked) {*/}
                                {/*                props.nationChange(watch('nazione_ship'));*/}
                                {/*            }*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                {/* START: Spedizione business */}
                                {/*{!watch('same_ship') && (*/}
                                {/*    <>*/}
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1">
                                                    Indirizzo*
                                                </Typography>
                                                <AutoComplete
                                                    ref={autocomplete}
                                                    className={'autoInputWhite'}
                                                    apiKey={'AIzaSyCUaGSi9hPaFrFAiy1LobhmtmJ0KCSvr1k'}
                                                    onPlaceSelected={(place) => {
                                                        setAddress(place);
                                                    }}
                                                    onBlur={() => {
                                                        if (place.formatted_address) {
                                                            setAddressBusiness(place);
                                                        }
                                                    }}
                                                    options={{
                                                        types: ['address']
                                                    }}
                                                    placeholder={'Inserisci un indirizzo'}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography variant="body1">
                                                    Informazioni aggiutive
                                                </Typography>
                                                <TextFieldElement
                                                    name="indirizzo2_ship"
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Nome referente
                                                </Typography>
                                                <TextFieldElement
                                                    required
                                                    name="riferimento"
                                                    label="Riferimento"
                                                    className="half"
                                                    validation={validation}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Cellulare
                                                </Typography>
                                                <TextFieldElement
                                                    required
                                                    name="telefono_ship"
                                                    label="Telefono"
                                                    className="half"
                                                    validation={validation}
                                                />
                                            </Grid>
                                        </Grid>
                                {/*    </>*/}
                                {/*)}*/}
                            </AccordionDetails>
                        </Accordion>
                    )}
                    {/*<Box px={3}>*/}
                    {/*    <ReCaptcha*/}
                    {/*        siteKey="6LeyCVEfAAAAAJdQtaGB_DCLUq2zbainH8Ne_tFJ"*/}
                    {/*        theme="light"*/}
                    {/*        size="normal"*/}
                    {/*        onSuccess={(captcha) => {*/}
                    {/*            props.setRecaptcha(true);*/}
                    {/*            formContext.setValue('g-recaptcha-response', captcha);*/}
                    {/*        }}*/}
                    {/*        onExpire={() => {*/}
                    {/*            props.setRecaptcha(false);*/}
                    {/*            formContext.setValue('g-recaptcha-response', '');*/}
                    {/*        }}*/}
                    {/*        onError={() => console.log('errore')}*/}
                    {/*    />*/}
                    {/*</Box>*/}
                    <Button ref={submitRef} type={'submit'} hidden={true} />
                </FormContainer>
            </Box>
        </React.Fragment>
    );
});
