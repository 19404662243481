import axios from "axios";
import authHeader from "../authentication/auth-header";
export const API_URL = "https://test.blorcompany.com/app";

export interface IRest {
  type: 'get' | 'post' | 'put' | 'delete';
  path: string;
  body?: any;
  headers?: any;
  params?: any;
  timeout?: number;
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
}

const requests: { [key: string]: Function } = {
  get: (url: string, params?: any, data?: any, headers?: any) => {
    return axios.get(url, { params, headers });
  },
  post: (url: string, params?: any, data?: any, headers?: any) => {
    return axios.post(url, data, { params, headers });
  },
  put: (url: string, params?: any, data?: any, headers?: any) => {
    return axios.put(url, data, { params, headers });
  },
  delete: (url: string, params?: any, data?: any, headers?: any) => {
    return axios.delete(url, { params, headers });
  }
};

class HttpService {

  async rest(options: IRest) {
    const headers = { ...authHeader(), ...options.headers };
    const params = { ...options.params };
    const data = { ...options.body };
    const path = `${API_URL}/${options.path}`;

    if (requests[options.type]) {
      console.log(options);
      const response = await requests[options.type](path, params, data, headers).catch((error: any) => error.response);
      console.log("Response: ", response!.data);
      if (response.status === 401) {
        localStorage.removeItem("user");
        window.location.href = "/";
      }
      if (response.status !== 200) {
        throw response;
      }
      if (options.onSuccess) {
        return options.onSuccess(response.data);
      }
      return response.data;
    }
  }
}
export default new HttpService();
